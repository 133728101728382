@import './theme';
// global css overrides for material components
// these overrides can not be done using css properties because angular does not provide them (yet). [@angular/material": "^7.0.2"]

// border color for material checkboxes

.mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mdc-checkbox__background {
  border-color: $active-input-color !important;
}

.mdc-checkbox-ripple {
  background-color: $cp-orange !important;
}

// mat-menu hover and active colors
.app-menu {
  & button.mat-menu-item {
    font-size: $font_14;
  }

  .mat-mdc-menu-item:hover:not([disabled]) {
    background: $hover-item-list
  }

  .mat-mdc-menu-item:active:not([disabled]),
  .mat-mdc-menu-item.cdk-focused:not([disabled]) {
    background: $active-item-list
  }
}

// hover and active styles for options
.app-option {
  &.mat-option {
    color: $font_color_default;
  }

  &.mat-option.mat-active:not(.mat-option-multiple) {
    background: $active-item-list !important;
    color: $font_color_default;
  }

  &.mat-option.mat-selected:not(.mat-option-multiple) {
    background: $active-item-list !important;
  }
}

// Begin Absence select combobox styles
.app-select-panel.mat-select-panel {
  position: absolute;
  border-radius: 3px;

  &:not([class*='mat-elevation-z']) {
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.35);
  }

  .mat-option {
    line-height: 1.38em;
    height: calc(1.38em + 5px + 5px);
    padding: 5px 10px;
  }
}

// End Absence select combobox styles


.mat-mdc-dialog-container {
  padding: 0 !important;
}

.tooltip-dialog__panel {
  .mat-mdc-dialog-container {
    background: transparent;
    box-shadow: none;
    display: inline-block;
    padding: 0;
    width: auto;
    height: auto;
    overflow: visible;
    visibility: hidden;
  }

  .mat-mdc-dialog-surface {
    overflow: visible;
  }
}

.tooltip-dialog__backdrop--invisible {
  background: transparent;
}

.mat-icon {
  user-select: none;
}

button[mat-icon-button] {

  &.icon-button-large {
    height: 50px !important;
    width: 50px !important;

    .mat-icon {
      height: 32px !important;
      width: 32px !important;

      .mat-badge-content {
        margin: -12px;
      }

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.select-options-container{
  margin-left: -13px !important;
  margin-top: -42px !important;
  width: 101% !important;
  padding: 0 !important;
}

.mat-mdc-header-row {
  height: 15px !important;
}

// Paginator
mat-paginator {
  mat-form-field {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border: none;
    }

    .mat-mdc-select-trigger{
      border-bottom: 1px solid black;
    }

    .mat-mdc-form-field-flex {
      height: 26px;
    }
  }
}



// Input Fields
.mat-mdc-form-field-infix:has(> textarea),
.mat-mdc-form-field-infix:has(> mat-select){
  padding: 0 !important;
}

// mat-form-field with outline
mat-form-field[appearance='outline'] {
  --mat-form-field-container-height: 48px;

  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  .mat-mdc-form-field-bottom-align{
    display: none;
  }
}

mdc-text-field--disabled {
  background-color: #dbdbdb !important;
}


