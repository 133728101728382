/* You can add global styles to this file, and also import other style files */
@import "app/mat-theme";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #ccc;
  @include default-font;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                        supported by Chrome and Opera */
}

.timebox-icon-button:hover {
  background-color: $mouse-over-buttons-color;
}

.timebox-icon-button:active {
  background-color: $mouse-pressed-button-color;
}


.clickable {
  cursor: pointer;
}

.mat-focused {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border-color: $cp-orange !important;
    border-width: 2px !important;
  }
}

.mat-mdc-form-field {
  // border: 0 solid black;
  padding: 0 !important;

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border-color: $cp-blue !important;
  }
}

.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
  height: 100%;
  padding-bottom: 0;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.global__scroll-container {
  scrollbar-width: thin;
  height: 100%;
  overflow: auto;
}
